import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from '@web3-react/core'

import { isMetaMaskInstalled } from './utils/isMetaMaskInstalled'

const Injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42]
 });
 
const ConnectWallet = () => {
  const { active, activate, deactivate, error } = useWeb3React();

  const connectWallet = () => {
    if (!isMetaMaskInstalled()) {
      alert("Please install MetaMask!");
      return
    }

    activate(Injected)
  }

  const disconnectWallet = () => {
    deactivate(Injected)
  }

  return (
    <div>
      {error !== undefined && <p className="default-color">{error.name}</p>}
    
      <div className="display-flex mb-10">
        {active ? (
          <div className="display-flex">
            <button onClick={disconnectWallet} className="btn btn-lg btn-color btn-square remove-margin">Disconnect</button>
          </div>
        ) : (
          <button onClick={connectWallet} className="btn btn-lg btn-color btn-square remove-margin">Connect</button>
        )}
      </div>
    </div>
  )
}

export default ConnectWallet;