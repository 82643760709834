import { useAuth0 } from "@auth0/auth0-react";
import { useWeb3React } from '@web3-react/core'
import axios from 'axios';
import { useState, useEffect } from 'react'

import ConnectWallet from './ConnectWallet'

import { CONFIG } from './config'

function App() {
  const { user, loginWithPopup, logout, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { active, account } = useWeb3React();

  const [apiError, setApiError] = useState()
  const [isRegistering, setIsRegistering] = useState()

  useEffect(() => {
    const header = document.getElementById('header')
    const sideMenu = document.getElementById('side-menu')
    const home = document.getElementById('home')
    const feature = document.getElementById('feature')
    const aboutUs = document.getElementById('about-us')
    const counters = document.getElementById('counters')
    const pricing = document.getElementById('pricing')
    const portfolio = document.getElementById('portfolio')
    const testimonials = document.getElementById('testimonials')
    const team = document.getElementById('team')
    const video = document.getElementById('video')
    const tools = document.getElementById('tools')
    const clients = document.getElementById('clients')
    const footerMain = document.getElementById('footer-main')

    if (isAuthenticated && user.wallet) {
      header.style.display = 'block'
      sideMenu.style.display = 'block'
      home.style.display = 'block'
      feature.style.display = 'block'
      aboutUs.style.display = 'block'
      counters.style.display = 'block'
      pricing.style.display = 'block'
      portfolio.style.height = 'auto'
      portfolio.style.paddingTop = '120px'
      testimonials.style.display = 'block'
      team.style.display = 'block'
      video.style.display = 'block'
      tools.style.display = 'block'
      clients.style.display = 'block'
      footerMain.style.display = 'block'
    } else {
      header.style.display = 'none'
      sideMenu.style.display = 'none'
      home.style.display = 'none'
      feature.style.display = 'none'
      aboutUs.style.display = 'none'
      counters.style.display = 'none'
      pricing.style.display = 'none'
      portfolio.style.height = '0'
      portfolio.style.paddingTop = '0'
      testimonials.style.display = 'none'
      team.style.display = 'none'
      video.style.display = 'none'
      tools.style.display = 'none'
      clients.style.display = 'none'
      footerMain.style.display = 'none'
    }
  }, [isAuthenticated, user])

  const login = async () => {
    await loginWithPopup({
      scope: "openid profile email read:current_user update:current_user_metadata",
    })
  }

  const signup = () => loginWithPopup({
    audience: `https://${CONFIG.IDENTITY.DOMAIN}/api/v2/`,
    scope: "openid profile email read:current_user update:current_user_metadata",
    screen_hint: 'signup'
  })

  const registerWallet = async () => {
    try {
      setIsRegistering(true)
      const token = await getAccessTokenSilently({
        audience: `https://${CONFIG.IDENTITY.DOMAIN}/api/v2/`,
        scope: "openid profile email read:current_user update:current_user_metadata",
      })
  
      await axios({
        method: 'POST',
        url: `${CONFIG.API.URL}`,
        headers: { 'Authorization': 'Bearer ' + token },
        data: {
          email: user.email,
          walletId: account
        }
      })

      await axios({
        method: 'patch',
        url: `https://${CONFIG.IDENTITY.DOMAIN}/api/v2/users/${user.sub}`,
        headers: { 'Authorization': 'Bearer ' + token},
        data: {
          user_metadata: { wallet: account },
        }
      })

      user.wallet = account
      setIsRegistering(false)
    } catch (e) {
      setIsRegistering(false)
      setApiError(e)
    }
  }

  if (isLoading || isRegistering) {
    return (
      <div class="text-left mt-10">
        <img src="assets/images/loader.svg" width="80" alt="" />
      </div>
    )
  }

  if (isAuthenticated) {

    if (!user.email_verified) return (
      <div>
        <p className="default-color">Email verification sent. Please verify your email first.</p>
        <button onClick={() => logout()} className="btn btn-lg btn-light btn-square remove-margin">Logout</button>
      </div>
    )

    return (
      <div>
        <p className="white-color">Connect your browser wallet to register</p>

        {apiError !== undefined && <p className="default-color">{apiError.message}</p>}
        
        {user.wallet ? (
          <p className="white-color mr-10">{user.wallet} registered</p>
        ) : (
          active ? (
            <>
              <p className="white-color mr-10">Connected {account}</p>
              <button onClick={registerWallet} className="btn btn-lg btn-color btn-square remove-margin">Register</button>
            </>
          ) : (
            <ConnectWallet />
          )
        )}
        

        <div className="display-flex items-center mt-100">
          <p className="white-color mr-10">Logged in as {user.email}</p>
          <button onClick={() => logout()} className="btn btn-lg btn-light btn-square remove-margin">Logout</button>
        </div>
      </div>
    )
  }

  return (
      <div className="text-left mt-10">
        <button onClick={login} className="btn btn-lg btn-color btn-square remove-margin">Login</button>
        <button onClick={signup} className="btn btn-lg btn-light btn-square remove-margin">Sign up</button>
      </div>
  );
}

export default App;
