import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from "@auth0/auth0-react";
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers";

import { CONFIG } from './config'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const getLibrary = (provider) => {
  return new Web3Provider(provider);
}

const authFormRoot = ReactDOM.createRoot(document.getElementById('auth-form'));
authFormRoot.render(
  <React.StrictMode>
    <Auth0Provider
      domain={CONFIG.IDENTITY.DOMAIN}
      clientId={CONFIG.IDENTITY.CLIENT_ID}
      redirectUri={window.location.origin}
      audience={`https://${CONFIG.IDENTITY.DOMAIN}/api/v2/`}
      scope="openid profile email read:current_user update:current_user_metadata"
    >
      <Web3ReactProvider getLibrary={getLibrary}>
        <App />
      </Web3ReactProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
