import { getEnvVariable } from './utils/getEnvVariable'

const CONFIG = {
  IDENTITY: {
    DOMAIN: getEnvVariable('IDENTITY_DOMAIN'),
    CLIENT_ID: getEnvVariable('IDENTITY_CLIENT_ID'),
  },
  API: {
    URL: getEnvVariable('API_URL'),
  }
}

export { CONFIG }
